<template>
  <div
    :class="
      `kt-grid kt-grid--hor kt-grid--root page-${$route.name} ${
        $route.meta !== undefined &&
        $route.meta !== null &&
        $route.meta.eclass !== undefined &&
        $route.meta.eclass !== null
          ? `eclass-${$route.meta.eclass}`
          : ''
      }`
    "
  >
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <div
        class="
          kt-grid__item kt-grid__item--fluid
          kt-grid kt-grid--hor
          kt-wrapper
        "
        id="kt_wrapper"
      >
        <div
          class="
            kt-content
            kt-grid__item kt-grid__item--fluid
            kt-grid kt-grid--hor
          "
          id="kt_content"
        >
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTFooter from "@/views/theme/footer/Footer.vue";
//import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
//import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
//import Loader from "@/views/partials/content/Loader.vue";
//import VOffline from "./VOffline.vue";
import {
  //CLEAR_CLASSNAMES,
  RESET_CLASSNAMES_BASIC
} from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    //VOffline,
    KTFooter
    //Loader,
  },
  beforeMount() {
    //this.$store.dispatch(CLEAR_CLASSNAMES);
    //Vue.prototype.$eventBus.emit( `page-loader`, 'Fetching client data' );
    //Vue.prototype.$eventBus.emit( `client-information-updated`, resp.data );
  },
  mounted() {
    // kt-quick-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled 
    // kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-header-base-light kt-header-menu-light kt-brand-light kt-aside-light
    this.$store.dispatch(RESET_CLASSNAMES_BASIC);
  },
  methods: {},
  computed: {
    ...mapGetters(["isAuthenticated", "layoutConfig"]),

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    }
  }
};
</script>

<style>
.BPCheaderIcon {
  width: 480px;
  max-width: 90%;
  margin: 0px auto;
  background-image: url(/favicon-primary.png);
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 50px;
}
</style>