var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`kt-grid kt-grid--hor kt-grid--root page-${_vm.$route.name} ${
      _vm.$route.meta !== undefined &&
      _vm.$route.meta !== null &&
      _vm.$route.meta.eclass !== undefined &&
      _vm.$route.meta.eclass !== null
        ? `eclass-${_vm.$route.meta.eclass}`
        : ''
    }`},[_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"},[_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper",attrs:{"id":"kt_wrapper"}},[_c('div',{staticClass:"kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor",attrs:{"id":"kt_content"}},[_c('div',{staticClass:"kt-container kt-grid__item kt-grid__item--fluid",class:{ 'kt-container--fluid': _vm.contentFluid }},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)]),_c('KTFooter')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }